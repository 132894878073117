import { Navigate } from 'react-router-dom';

import DashboardLayout from '../views/layouts/AppLayout';
import BackGroundOnlyLayout from '../views/layouts/BackGroundOnlyLayout';

import Login from '../views/pages/app/auth/Login';

// import Signature from '../views/pages/app/signature';

import NotFound from '../views/pages/app/notfound/Page404';

// Menu
import DashboardApp from '../views/pages/app/dashboard/DashboardApp';
import MyAccount from '../views/pages/app/account';
// Usuários
import Users from '../views/pages/app/users';
import AddEditUsers from '../views/pages/app/users/AddEditUsers';
// Permissoes
import Permissions from '../views/pages/app/permissions';
// Associados
import Clients from '../views/pages/app/clients';
import AddEditClients from '../views/pages/app/clients/AddEditClients';

import Tables from '../views/pages/app/tables';
import AddEditTables from '../views/pages/app/tables/AddEditTables';
// Repasses
import Transfers from '../views/pages/app/Transfers';
import AddEditTransfers from '../views/pages/app/Transfers/AddEditTransfers';

import Readjustments from '../views/pages/app/tables/Readjustments';

// Parceiros
import Partners from '../views/pages/app/partners';
import AddEditpartners from '../views/pages/app/partners/AddEditPartners';

// Empresas
import Companies from '../views/pages/app/companies';
import AddEditAssociation from '../views/pages/app/companies/AddEditCompanies';

// Comodidades
import Amenities from '../views/pages/app/Amenities';
import AddEditAmenities from '../views/pages/app/Amenities/AddEditAmenities';

// Avisos
import Warnings from '../views/pages/app/warnings';
import AddEditWarnings from '../views/pages/app/warnings/addEditWarnings';
// Formulários

// Financeiro
// Produtos
import Products from '../views/pages/app/products';
import AddEditProducts from '../views/pages/app/products/AddEditProducts';
// Configuração
import Config from '../views/pages/app/config';
// Email
import Email from '../views/pages/app/email';

// import LinkAmenities from '../views/pages/app/brokers/LinkAmenities';
// import LinkContacts from '../views/pages/app/brokers/LinkContacts';
// import LinkSocialMedia from '../views/pages/app/brokers/LinkSchedules';
// import LinkSchedules from '../views/pages/app/brokers/LinkSocialMedia';
// import LinkPlans from '../views/pages/app/brokers/LinkPlans';
// import Forms from '../views/pages/app/forms';
// import AddEditForm from '../views/pages/app/forms/AddEditForm';
// import Simulations from '../views/pages/app/simulations/index';
// import AddSimulations from '../views/pages/app/simulations/AddEditSimulations';
// import RepSales from '../views/pages/app/reports/RepSales';
// import RepSimulations from '../views/pages/app/reports/RepSimulations';

// import DeclarationHealth from '../views/pages/app/declarationHealth';
// import Operators from '../views/pages/app/operators';
// import AddEditOperator from '../views/pages/app/operators/AddEditOperator';
// import Shortage from '../views/pages/app/shortages';
// import AddEditShortages from '../views/pages/app/shortages/addEditShortages';
// import Comissions from '../views/pages/app/commissions';

const routes = (isLoggedIn, permissions) => {
  const routers = [
    {
      path: '/app',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        // Dashboard
        { name: 'Dashboard', path: 'dashboard', element: <DashboardApp />, exact: true },
        // Minha Conta
        {
          name: 'Minha conta',
          path: 'myAccount',
          element: (
            <MyAccount
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Minha conta', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Usuários
        {
          name: 'Usuários',
          path: 'users',
          element: (
            <Users
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Usuários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Usuários',
          path: 'addUsers',
          element: (
            <AddEditUsers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Usuários', path: '/app/users', active: false },
                { name: 'Cadastro de usuários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Usuários',
          path: 'editUsers/:id',
          element: (
            <AddEditUsers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Usuários', path: '/app/users', active: false },
                { name: 'Cadastro de usuários', active: true }
              ]}
            />
          ),
          exact: true
        },
        // Permissões
        {
          name: 'Permissões padrões',
          path: 'permissions',
          element: (
            <Permissions
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Permissões padrões', active: true }
              ]}
            />
          )
        },
        // - Associados
        {
          name: 'Associados',
          path: 'clients',
          element: (
            <Clients
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Associados ativos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Associados',
          path: 'addClients',
          element: (
            <AddEditClients
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Associados', path: '/app/clients', active: false },
                { name: 'Cadastro de associados', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Associados',
          path: 'editClients/:id',
          element: (
            <AddEditClients
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Associados', path: '/app/clients', active: false },
                { name: 'Edição de associados', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Parceiros
        {
          name: 'Parceiros',
          path: 'partners',
          element: (
            <Partners
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Parceiros', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Parceiros',
          path: 'addPartners/',
          element: (
            <AddEditpartners
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Parceiros', path: '/app/partners', active: false },
                { name: 'Cadastro de parceiros', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Parceiros',
          path: 'editPartners/:id',
          element: (
            <AddEditpartners
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Parceiros', path: '/app/partners', active: false },
                { name: 'Cadastro de parceiros', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Empresas
        {
          name: 'Empresas',
          path: 'companies',
          element: (
            <Companies
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Empresas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Empresas',
          path: 'addCompanies/',
          element: (
            <AddEditAssociation
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Empresas', path: '/app/addCompanies', active: false },
                { name: 'Cadastro de empresas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Empresas',
          path: 'editCompanies/:id',
          element: (
            <AddEditAssociation
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Empresas', path: '/app/companies', active: false },
                { name: 'Edição de empresas', active: true }
              ]}
            />
          ),
          exact: true
        },
        // Comodidades
        {
          name: 'Comodidades',
          path: 'Amenities',
          element: (
            <Amenities
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Comodidades', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Comodidades',
          path: 'addAmenities/',
          element: (
            <AddEditAmenities
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Comodidades', path: '/app/amenities', active: false },
                { name: 'Cadastro de comodidades', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Comodidades',
          path: 'editAmenities/:id',
          element: (
            <AddEditAmenities
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Comodidades', path: '/app/amenities', active: false },
                { name: 'Cadastro de comodidades', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Avisos
        // {
        //   name: 'Avisos',
        //   path: 'warnings',
        //   element: (
        //     <Warnings
        //       breadcrumb={[
        //         { name: 'Dashboard', path: '/app/dashboard', active: false },
        //         { name: 'Avisos', active: true }
        //       ]}
        //     />
        //   ),
        //   exact: true
        // },
        // {
        //   name: 'Avisos',
        //   path: 'addWarning/',
        //   element: (
        //     <AddEditWarnings
        //       breadcrumb={[
        //         { name: 'Dashboard', path: '/app/dashboard', active: false },
        //         { name: 'Avisos', path: '/app/warnings', active: false },
        //         { name: 'Cadastro de avisos', active: true }
        //       ]}
        //     />
        //   ),
        //   exact: true
        // },
        {
          name: 'Avisos',
          path: 'editWarning/:id',
          element: (
            <AddEditWarnings
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Avisos', path: '/app/warnings', active: false },
                { name: 'Cadastro de avisos', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Formulários
        {
          name: 'Formulários',
          path: 'forms',
          element: (
            <Warnings
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Formulários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Formulários',
          path: 'addForms/',
          element: (
            <AddEditWarnings
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Formulários', path: '/app/warnings', active: false },
                { name: 'Cadastro de formulários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Formulários',
          path: 'editForms/:id',
          element: (
            <AddEditWarnings
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Formulários', path: '/app/warnings', active: false },
                { name: 'Cadastro de formulários', active: true }
              ]}
            />
          ),
          exact: true
        },
        // Financeiro
        // - Produtos
        {
          name: 'Produtos',
          path: 'products',
          element: (
            <Products
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Produtos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Produtos',
          path: 'addProducts',
          element: (
            <AddEditProducts
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Produtos', path: '/app/products', active: false },
                { name: 'Cadastro de produtos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Produtos',
          path: 'editProducts/:id',
          element: (
            <AddEditProducts
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Produtos', path: '/app/products', active: false },
                { name: 'Cadastro de produtos', active: true }
              ]}
            />
          ),
          exact: true
        },
        //- Tabelas
        {
          name: 'Tabelas',
          path: 'tables',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Tabelas',
          path: 'addTables',
          element: (
            <AddEditTables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', path: '/app/tables', active: false },
                { name: 'Cadastro de tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Tabelas',
          path: 'editTables/:id',
          element: (
            <AddEditTables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', path: '/app/tables', active: false },
                { name: 'Cadastro de tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        //- Repasses
        {
          name: 'Repasses',
          path: 'transfers',
          element: (
            <Transfers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Repasses', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Repasses',
          path: 'addTransfers',
          element: (
            <AddEditTransfers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Repasses', path: '/app/transfers', active: false },
                { name: 'Cadastro de repasses', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Repasses',
          path: 'editTransfers/:id',
          element: (
            <AddEditTransfers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Repasses', path: '/app/transfers', active: false },
                { name: 'Cadastro de repasses', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Reajustes
        {
          name: 'Reajustes',
          path: 'readjustments',
          element: (
            <Readjustments
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', path: '/app/tables', active: false },
                { name: 'Reajustar tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Contas a Pagar
        {
          name: 'Contas a Pagar',
          path: 'billsToPay',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Contas a pagar', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Contas a Receber
        {
          name: 'Contas a receber',
          path: 'billsToReceive',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Contas a receber', active: true }
              ]}
            />
          ),
          exact: true
        },
        // Faturamento
        // - Abrir Competencias
        {
          name: 'Competências',
          path: 'competence',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Competências', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Competências',
          path: 'addCompetence',
          element: (
            <AddEditTables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Competências', path: '/app/tables', active: false },
                { name: 'Cadastro de competências', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Competências',
          path: 'editCompetence/:id',
          element: (
            <AddEditTables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Competências', path: '/app/tables', active: false },
                { name: 'Cadastro de competências', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Gerar Faturamento
        {
          name: 'Gerar Faturamento',
          path: 'generateBilling',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Gerar Faturamento', active: true }
              ]}
            />
          ),
          exact: true
        },
        // - Manutenção de Faturas
        {
          name: 'Manutenção de Faturamento',
          path: 'maintenanceBilling',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Manutenção de Faturamento', active: true }
              ]}
            />
          ),
          exact: true
        },
        // SAC
        {
          name: 'SAC',
          path: 'openSAC',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'SAC', active: true }
              ]}
            />
          ),
          exact: true
        },

        // Relatórios

        // Configurações
        // - Configuração de Email
        {
          name: 'Configurações de email',
          path: 'email',
          element: (
            <Email
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Configurações de email', active: true }
              ]}
            />
          ),
          exact: true
        },
        // Configuração do sistema
        {
          name: 'Configurações do sistema',
          path: 'config',
          element: (
            <Config
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Configurações do sistema', active: true }
              ]}
            />
          ),
          exact: true
        }
      ]
    },
    {
      path: '/',
      element: <BackGroundOnlyLayout />,
      children: [
        { path: '/', element: <Login /> },
        { path: '/:cliente', element: <Login /> },
        { path: '/:cliente/client', element: <Login /> },
        { path: 'login', element: <Login />, exact: true },
        { path: '404', element: <NotFound />, exact: true },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];

  const routes = [];

  if (!permissions) {
    return routers;
  }
  routers.map((item) => {
    if (item.path == '/') {
      routes.push(item);
    }
    if (item.path == '*') {
      routes.push(item);
    }
    if (item.path === '/app' && item.children) {
      item.children.map((child) => {
        permissions.map((permission) => {
          if (child.name === permission.name) {
            routes.push({
              path: '/app',
              element: <DashboardLayout />,
              children: [child]
            });
          }
        });
      });
    }
  });
  return routes;
};

export default routes;
