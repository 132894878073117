/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { IconButton, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import Iconify from '../../../../@core/theme/Iconify';
import palette from 'src/@core/theme/palette';

const allowedPageSizes = [5, 10, 20, 30];

const LinkPlans = (props) => {
  const [infosPlans, setInfosPlans] = useState([]);
  const [rowsSelected, setRowsSelected] = useState();

  useEffect(() => {
    getPlans();
  }, []);

  function getPlans() {
    configAxios
      .get('/products/getProducts')
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos! Tente novamente!',
          'Atenção'
        );
      });
  }

  function selectedRows(event) {
    setRowsSelected(event.selectedRowsData);
  }

  function HandleSubmit(event) {
    event.preventDefault();

    if (rowsSelected) {
      const id_association = props.otherInfos.id;
      const infosPlans = [];

      rowsSelected.forEach((element) => {
        infosPlans.push({ codplano: element.codplano, idCompany: id_association });
      });
      configAxios
        .post('/companys/linkPlansToCompany', infosPlans)
        .then(() => {
          props.setOpenModal(false);
          props.functionGrid();
          NotificationManager.success('Plano(s) vinculado(s) com sucesso!', 'Sucesso');
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para vincular os planos! Tente novamente!',
            'Atenção'
          );
        });
    } else {
      NotificationManager.warning('Selecione os planos que deseja vincular!', 'Atenção');
    }
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            sx={{
              color: palette.primary.contrastText
            }}
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            Vincular planos a empresa
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <CustomGrid>
                <DataGrid
                  dataSource={infosPlans}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onSelectionChanged={selectedRows}>
                  <Selection mode="multiple" />
                  <Column dataField="id" width={70} />
                  <Column dataField="codplano" caption="Código do plano" />
                  <Column dataField="descplano" caption="Descrição do plano" />
                  <Column dataField="tpcontrat" caption="Tipo de contratação" />
                  <Column dataField="status" caption="Status" />
                  <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                  {!isMobile && (
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                  )}
                  <Scrolling rowRenderingMode="virtual"></Scrolling>
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser
                    enabled={true}
                    title={'Escolher uma coluna'}
                    emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                  />
                  <ColumnFixing enabled={true} />
                  <HeaderFilter visible={true} allowSearch={true} />
                </DataGrid>
              </CustomGrid>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                startIcon={<Iconify icon="eva:save-fill" />}>
                Vincular
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};
export default LinkPlans;
