/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { IconButton, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import InputMask from 'react-input-mask';

import { TextField, Grid, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import Iconify from '../../../../@core/theme/Iconify';
import palette from 'src/@core/theme/palette';

const LinkSocialMedia = (props) => {
  const [socialMedia, setSocialMedia] = useState([]);
  const [socialMediaItens, setSocialMediaItens] = useState([]);
  const [infosSocialMedia, setInfosSocialMedia] = useState({});
  const [rowsSelected, setRowsSelected] = useState({});
  useEffect(() => {
    setSocialMedia([]);
    setSocialMediaItens([]);
    setInfosSocialMedia({});
    setRowsSelected({});
    getSocialMedia();
  }, []);

  useEffect(() => {
    if (props.editMode === true) {
      socialMedia.map((item) => {
        if (props.SocialInfos.codsocial === item.codsocial) {
          setSocialMediaItens([item]);
          setRowsSelected(item);
          setInfosSocialMedia(props.SocialInfos);
        }
      });
    } else {
      setSocialMediaItens(socialMedia);
    }
  }, [props]);

  function getSocialMedia() {
    configAxios
      .get('/social/getSocialMedias')
      .then((response) => {
        setSocialMedia(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos! Tente novamente!',
          'Atenção'
        );
      });
  }

  //   Add Social Media to Partners
  function HandleSubmit(event) {
    event.preventDefault();
    let url = '';
    if (props.editMode === true) {
      url = `/establishments/updateSocialEstablishment/${infosSocialMedia.id}`;
    } else {
      url = '/establishments/linkSocialMediaToEstablishment';
    }

    if (infosSocialMedia) {
      configAxios
        .post(url, infosSocialMedia)
        .then(() => {
          props.setOpenModal(false);
          props.functionGrid();
          NotificationManager.success('Rede(s) social(is) vinculada(s) com sucesso!', 'Sucesso');
          setInfosSocialMedia({});
          setRowsSelected({});
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para vincular as redes sociais! Tente novamente!',
            'Atenção'
          );
        });
    } else {
      NotificationManager.warning('Selecione as redes sociais que deseja vincular!', 'Atenção');
    }
  }

  function HandleInputChange(event, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosSocialMedia({
          ...infosSocialMedia,
          codsocial: event.codsocial,
          idEstablishment: props.otherInfos.id
        });
        setRowsSelected({
          label: event.label
        });
        return;
      }
      return;
    }
    setInfosSocialMedia({ ...infosSocialMedia, [event.target.name]: event.target.value });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            style={{
              color: palette.primary.contrastText,
              input: { color: 'black' }
            }}
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            {props.editMode === true
              ? 'Editar rede social do parceiro'
              : 'Vincular rede social do parceiro'}
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 1,
                  input: { color: palette.primary.contrastText }
                }}>
                <Grid item xs={5} md={4}>
                  <Autocomplete
                    sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}
                    options={socialMediaItens}
                    renderOption={(props, option) => (
                      <li style={{ color: palette.primary.contrastText }} {...props}>
                        {option.label}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, { autocomplete: true })}
                    value={rowsSelected.label || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}
                        {...params}
                        label="Mídia Social"
                        name="social"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
                {infosSocialMedia.codsocial === '0003' || infosSocialMedia.codsocial === '0005' ? (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={infosSocialMedia.link || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="link"
                          fullWidth
                          label="Celular"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}>
                    <TextField
                      name="link"
                      fullWidth
                      label="Link rede Social"
                      variant="outlined"
                      size="small"
                      required
                      value={infosSocialMedia.link || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                startIcon={<Iconify icon="eva:save-fill" />}>
                {props.editMode === true ? 'Editar' : 'Vincular'}
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default LinkSocialMedia;
