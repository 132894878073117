/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import { selectLogin } from '../../../../store/login/loginSlice';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import { useDebounce } from 'use-debounce';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  Box
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import CopyItems from './CopyItems';
import AddEditItem from './AddEditItem';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import palette from 'src/@core/theme/palette';

const addEditTables = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');

  // const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({});
  // const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const [infosTables, setInfosTables] = useState(state ? { ...state.infosEdit } : {});
  const [infosValPlan, setInfosValPlan] = useState([]);
  const [infosValPlanEdit, setInfosValPlanEdit] = useState({});
  const [infosProducts, setInfosProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const allowedPageSizes = [5, 10, 20, 30];

  const key = useRef('');
  const [value] = useDebounce(key.current, 1000);

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editTables') [navigation('/app/tables')];
    if (infosTables.id) {
      getValPlan();
    }
    getProductsLabel();
  }, []);

  function getValPlan() {
    configAxios
      .get(`/tables/getValPlan/${infosTables.codtab}`)
      .then((response) => {
        setInfosValPlan(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os valores, tente novamente!',
          'Atenção'
        );
      });
  }

  function getProductsLabel() {
    configAxios
      .get('/products/getProductsLabel')
      .then((response) => {
        setInfosProducts(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos, tente novamente!',
          'Atenção'
        );
      });
  }

  function FormatDate(infos) {
    let str = infos.data.data.mesref.toString();
    let year = str.substring(0, 4).toString();
    let month = str.substring(5, 7).toString();
    let dateFormated = month + '/' + year;
    return dateFormated;
  }

  function AddItemsModal(id) {
    if (id) {
      const valuesEdit = infosValPlan.filter((element) => element.id === id);
      setInfosValPlanEdit(valuesEdit);
    }
    setOpenModalEdit(true);
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/tables/deleteValPlan',
          deleteItem: 'o item',
          functionGrid: getValPlan,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  function HandleInputChange(event, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosTables({ ...infosTables, label: event.label, codplano: event.codplano });
        return;
      }
      return;
    }
    setInfosTables({ ...infosTables, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosTables.id ? '/tables/updateTable' : '/tables/addTable';
    configAxios
      .post(router, infosTables)
      .then((response) => {
        const message = infosTables.id
          ? 'Tabela atualizada com sucesso!'
          : 'Tabela inserida com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        key.current = Math.random();
        getValPlan();
        setInfosTables({
          ...infosTables,
          id: response.data.id,
          codtab: response.data.codtab
        });
        const nav = infosTables.id
          ? `/app/editTables/${infosTables.id}`
          : `/app/editTables/${response.data.id}`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infosTables,
              id: infosTables.id ? infosTables.id : response.data.id,
              codtab: infosTables.codtab ? infosTables.codtab : response.data.codtab
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Tabelas" id="table">
      <>
        <CopyItems
          openModal={openModal}
          setOpenModal={setOpenModal}
          functionGrid={getValPlan}
          otherInfos={infosTables}
          infosItensValPlan={infosValPlan}
        />
        <AddEditItem
          openModal={openModalEdit}
          setOpenModal={setOpenModalEdit}
          functionGrid={getValPlan}
          infosValPlan={infosValPlan}
          infosItemEdit={infosValPlanEdit}
          otherInfos={infosTables}
          key={value}
        />
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de tabelas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar as tabelas
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/tables"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                  Campos obrigatórios possuem asterisco (*)
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  {infosTables.id && (
                    <Grid item xs={2} md={1}>
                      <TextField
                        name="idtable"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosTables.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                  {infosTables.codtab && (
                    <Grid item xs={6} md={2}>
                      <TextField
                        name="codtab"
                        fullWidth
                        label="Código da tabela"
                        variant="outlined"
                        size="small"
                        required
                        value={infosTables.codtab || ''}
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="desctab"
                      fullWidth
                      label="Descrição da tabela"
                      variant="outlined"
                      size="small"
                      required
                      value={infosTables.desctab || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      sx={isMobile ? { mt: 1 } : { mt: 0 }}
                      options={infosProducts}
                      renderOption={(props, option) => (
                        <li style={{ color: palette.primary.contrastText }} {...props}>
                          {option.label}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleInputChange(value, true)}
                      value={infosTables.label || ''}
                      defaultValue=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Código do plano"
                          name="codplano"
                          fullWidth
                          size="small"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={infoState.tipointegracao !== 'Não possui' ? 3 : 4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                      Status *
                    </InputLabel>
                    <Select
                      style={{ color: palette.primary.contrastText }}
                      name="status"
                      fullWidth
                      size="small"
                      required
                      value={infosTables.status || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem style={{ color: palette.primary.contrastText }} value={'ATIVO'}>
                        Ativo
                      </MenuItem>
                      <MenuItem style={{ color: palette.primary.contrastText }} value={'INATIVO'}>
                        Inativo
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                {infosTables.id && (
                  <>
                    <CustomGrid sx={{ mt: 1 }}>
                      <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Informações sobre valores dos itens da tabela
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        <Stack direction="row" alignItems="center" justifyContent="end">
                          <Button
                            variant="contained"
                            onClick={AddItemsModal}
                            startIcon={<Iconify icon="eva:plus-fill" />}>
                            Novo
                          </Button>
                          <Button
                            variant="outlined"
                            color="success"
                            id="copyItems"
                            sx={{ ml: 1 }}
                            onClick={() => setOpenModal(true)}
                            startIcon={<Iconify icon="eva:copy-fill" />}>
                            Copiar
                          </Button>
                          {!isMobile && (
                            <Button
                              variant="outlined"
                              onClick={getValPlan}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                      <DataGrid
                        dataSource={infosValPlan}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não foram encontrados registros'}>
                        <Column dataField="codtab" caption="Código da tabela" />
                        <Column
                          dataField="mesref"
                          caption="Mês de referência"
                          alignment="left"
                          cellRender={(data) => <FormatDate data={data} />}
                        />
                        <Column dataField="valor" caption="Valor R$" alignment="left" />
                        <Column
                          dataField="Ações"
                          width={150}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtons data={data} />}
                        />
                        <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />
                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </CustomGrid>
                  </>
                )}
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosTables.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/tables"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default addEditTables;
