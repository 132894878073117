import React from 'react';
import Iconify from '../../@core/theme/Iconify';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

// Arquivo para arrumar o menu
const menuSideBar = [
  {
    title: 'Dashboard',
    path: '/app/dashboard',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'Cadastros',
    icon: <AddToQueueIcon />,
    children: [
      {
        title: 'Controle de acesso',
        children: [
          {
            title: 'Usuários',
            path: '/app/users'
          },
          {
            title: 'Permissões padrões',
            path: '/app/permissions'
          }
        ]
      },
      {
        title: 'Associados',
        path: '/app/clients'
      },
      {
        title: 'Parceiros',
        path: '/app/partners'
      },
      {
        title: 'Empresas',
        path: '/app/companies'
      },
      {
        title: 'Comodidades',
        path: '/app/amenities'
      },
      {
        title: 'Avisos',
        path: '/app/warnings'
      },
      {
        title: 'Formulários',
        path: '/app/forms'
      }
    ]
  },
  {
    title: 'Financeiro',
    icon: <LocalAtmIcon />,
    children: [
      {
        title: 'Cadastros',
        children: [
          {
            title: 'Produtos',
            path: '/app/products'
          },
          {
            title: 'Tabelas',
            path: '/app/tables'
          },
          {
            title: 'Repasses',
            path: '/app/transfers'
          },
          {
            title: 'Reajustes',
            path: '/app/readjustments'
          }
        ]
      },
      {
        title: 'Contas a Pagar',
        path: '/app/billsToPay'
      },
      {
        title: 'Contas a receber',
        path: '/app/billsToReceive'
      }
    ]
  },
  {
    title: 'Faturamento',
    icon: <RequestQuoteIcon />,
    children: [
      {
        title: 'Competências',
        path: '/app/competence'
      },
      {
        title: 'Gerar Faturamento',
        path: '/app/generateBilling'
      },
      {
        title: 'Manutenção de Faturamento',
        path: '/app/maintenanceBilling'
      }
    ]
  },
  {
    title: 'SAC',
    icon: <SupportAgentIcon />,
    path: '/app/openSAC'
  },
  {
    title: 'Relatórios',
    icon: getIcon('eva:activity-outline'),
    children: [
      {
        title: 'Financeiro',
        children: [
          {
            title: 'Relatório de vendas',
            path: '/app/reports/repSales'
          },
          {
            title: 'Relatório de simulações',
            path: '/app/reports/repSimulations'
          }
        ]
      }
    ]
  },
  {
    title: 'Configurações',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Configurações de email',
        path: '/app/email'
      },
      {
        title: 'Configurações do sistema',
        path: '/app/config'
      }
    ]
  }
];

const navConfig = (permissions) => {
  let menu = [];

  menuSideBar.map((item) => {
    permissions.map((permission) => {
      if (permission.name === item.title) {
        menu.push(item);
      }
      if (item.children) {
        item.children.map((child) => {
          if (permission.name === child.title) {
            if (!menu.filter((e) => e.title === item.title).length > 0) {
              menu.push({
                title: item.title,
                icon: item.icon,
                children: [child]
              });
            } else {
              menu.map((m) => {
                if (m.title === item.title) {
                  m.children.push(child);
                }
              });
            }
          } else {
            if (child.children) {
              child.children.map((subChild) => {
                if (permission.name === subChild.title) {
                  if (!menu.filter((e) => e.title === item.title).length > 0) {
                    child.children.map((subChild2) => {
                      if (!menu.filter((e) => e.title === subChild2.title).length > 0) {
                        if (subChild2.title === subChild.title) {
                          menu.push({
                            title: item.title,
                            icon: item.icon,
                            children: [
                              {
                                title: child.title,
                                children: [subChild2]
                              }
                            ]
                          });
                        } else {
                          menu.map((m) => {
                            if (m.title === item.title) {
                              m.children.map((c) => {
                                permissions.map((p) => {
                                  if (p.name === subChild2.title) {
                                    c.children.push(subChild2);
                                  }
                                });
                              });
                            }
                          });
                        }
                      } else {
                        menu.map((m) => {
                          if (m.title === item.title) {
                            m.children.push(subChild2);
                          }
                        });
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    });
  });
  return [...new Set(menu)];
};

export default navConfig;
