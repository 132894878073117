import axios from 'axios';
import { startAndStop } from '../store/loading/loadingSlice';
import { logout } from '../store/login/loginSlice';
import { store } from '../store/index';

// const baseURL =8
// process.env.NODE_ENV === 'production' ? 'https://app.phorm.com.br:3001' : 'http://localhost:3001';

const baseURL = 'https://app.phorm.com.br:3001';

const configAxios = axios.create({
  baseURL
});

const requestHandler = (request) => {
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error.response.status === 401) {
    store.dispatch(logout());
    store.dispatch(startAndStop(false));
    return;
  }
  store.dispatch(startAndStop(false));
  throw error;
};

configAxios.interceptors.request.use(
  (request) => {
    const state = store.getState();
    const userAuth = state.login.authenticatedUser;
    if (userAuth.token) {
      const headers = {
        authorization: userAuth.token,
        'x-access-user-id': userAuth.userLoggedIn[0].id,
        'x-mode': userAuth.userLoggedIn[0].tipo
      };
      request.headers = headers;
    } else {
      const headers = {
        'x-access-company': 'PHORM'
      };

      request.headers = headers;
    }
    if (request.url.split('/')[2] !== 'getNotifications') {
      store.dispatch(startAndStop(true));
    }
    return requestHandler(request);
  },
  (error) => errorHandler(error)
);

configAxios.interceptors.response.use(
  (response) => {
    store.dispatch(startAndStop(false));
    return responseHandler(response);
  },
  (error) => {
    errorHandler(error);
  }
);

export default configAxios;
