/* eslint-disable no-unused-vars */
/**
 * tables: tb_profiles & tb_type_users
 * profiles: 1 = admin, 2 = usuario, 3 = vendedor
 * typeusers: 1 = operadora 2 = corretora  3 = cliente
 */
import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, Alert, Fade } from '@mui/material';
import { AppGraphs, AppWidgetSummary } from '../../../sections/dashboard/dash';
import { selectLogin } from '../../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';

const DashboardApp = () => {
  const infoStateTemp = useSelector(selectLogin);
  // const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [infoState] = useState({});
  const [infosSummaryClients, setInfosSummaryClients] = useState(0);
  const [infosSummarySimulations, setInfosSummarySimulations] = useState(0);
  const [infosSummaryClientsApproved, setInfosSummaryClientsApproved] = useState(0);
  const [infosSummarySimulationsFinished, setInfosSummarySimulationsFinished] = useState(0);
  const [infosLabelsGraphsClients, setInfosLabelsGraphsClients] = useState([]);
  const [infosDataGraphsClients, setInfosDataGraphsClients] = useState([]);
  const [infosLabelsGraphsSimulations, setInfosLabelGraphsSimulations] = useState([]);
  const [infosDataGraphsSimulations, setInfosDataGraphsSimulations] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [warning, setWarning] = useState({});

  useEffect(() => {
    Promise.all([
      getInfosSummaryClients(),
      getInfosSummarySimulations(),
      getInfosGraphsClients(),
      getInfosGraphsSimulations(),
      getWarningDisplay()
    ]);
  }, []);

  async function getInfosSummaryClients() {
    let router;

    if (infoState.id_type_user === 1) {
      router = `/dashboard/summaryClients/?id_type_user=${infoState.id_type_user}`;
    } else if (
      (infoState.id_type_user === 2 && infoState.id_profile === 1) ||
      infoState.id_profile === 2
    ) {
      router = `/dashboard/summaryClients/?id_type_user=${infoState.id_type_user}&id_broker=${infoState.id_broker}`;
    } else {
      router = `/dashboard/summaryClients/?id_type_user=${infoState.id_type_user}&id_broker=${infoState.id_broker}&id_user=${infoState.id}`;
    }

    await configAxios
      .get(router)
      .then((response) => {
        let infosSummaryClientsCount = 0;
        let infosSummaryClientsApprovedCount = 0;

        response.data.map((element) => {
          if (element.statusvenda === 'Aprovada') {
            infosSummaryClientsApprovedCount++;
          } else {
            infosSummaryClientsCount++;
          }
        });
        setInfosSummaryClients(infosSummaryClientsCount);
        setInfosSummaryClientsApproved(infosSummaryClientsApprovedCount);
      })
      .catch(() => {
        // NotificationManager.error(
        //   'Tivemos dificuldades para buscar as informações sobre os clientes! Tente novamente!',
        //   'Atenção'
        // );
      });
  }

  async function getInfosSummarySimulations() {
    let router;

    if (infoState.id_type_user === 1) {
      router = `/dashboard/summarySimulations/?id_type_user=${infoState.id_type_user}`;
    } else if (
      infoState.id_type_user === 2 &&
      (infoState.id_profile === 1 || infoState.id_profile === 2)
    ) {
      router = `/dashboard/summarySimulations/?id_type_user=${infoState.id_type_user}&id_broker=${infoState.id_broker}`;
    } else {
      router = `/dashboard/summarySimulations/?id_type_user=${infoState.id_type_user}&id_broker=${infoState.id_broker}&id_user=${infoState.id}`;
    }

    await configAxios
      .get(router)
      .then((response) => {
        let infosSummarySimulationsCount = 0;
        let infosSummarySimulationsFinishedCount = 0;
        response.data.map((element) => {
          if (element.status === 'Fechada') {
            infosSummarySimulationsFinishedCount++;
          } else {
            infosSummarySimulationsCount++;
          }
        });
        setInfosSummarySimulations(infosSummarySimulationsCount);
        setInfosSummarySimulationsFinished(infosSummarySimulationsFinishedCount);
      })
      .catch(() => {
        // NotificationManager.error(
        //   'Tivemos dificuldades para buscar as informações sobre as simulações! Tente novamente!',
        //   'Atenção'
        // );
      });
  }

  async function getInfosGraphsClients() {
    let router;

    if (infoState.id_type_user === 1) {
      router = '/dashboard/graphsClients';
    } else if (
      infoState.id_type_user === 2 &&
      (infoState.id_profile === 1 || infoState.id_profile === 2)
    ) {
      router = `/dashboard/graphsClients/?id_broker=${infoState.id_broker}`;
    } else {
      router = `/dashboard/graphsClients/?id_broker=${infoState.id_broker}&id_user=${infoState.id}`;
    }

    await configAxios
      .get(router)
      .then((response) => {
        const labels = [];
        const data = [];

        response.data.map((element) => {
          labels.push(element.day);
        });

        response.data.map((element) => {
          data.push(element.dia);
        });

        setInfosLabelsGraphsClients(labels);
        setInfosDataGraphsClients(data);
      })
      .catch(() => {
        // NotificationManager.error(
        //   'Tivemos dificuldades para buscar as informações sobre os gráficos das vendas! Tente novamente!',
        //   'Atenção'
        // );
      });
  }

  async function getWarningDisplay() {
    const direcao = infoState.id_type_user === 1 ? 'Operadora' : 'Corretora';
    configAxios
      .get(`warnings/getWarning/${direcao}`)
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          setWarning(response.data);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 10000);
        }
      })
      .catch(() => {
        // NotificationManager.error(
        //   'Tivemos dificuldades para buscar os avisos, por favor, tente novamente!',
        //   'Atenção'
        // );
      });
  }

  async function getInfosGraphsSimulations() {
    let router;

    if (infoState.id_type_user === 1) {
      router = `/dashboard/graphsSimulations/?id_type_user=${infoState.id_type_user}`;
    } else if (
      infoState.id_type_user === 2 &&
      (infoState.id_profile === 1 || infoState.id_profile === 2)
    ) {
      router = `/dashboard/graphsSimulations/?id_type_user=${infoState.id_type_user}&id_broker=${infoState.id_broker}`;
    } else {
      router = `/dashboard/graphsSimulations/?id_type_user=${infoState.id_type_user}&id_broker=${infoState.id_broker}&id_user=${infoState.id}`;
    }

    await configAxios
      .get(router)
      .then((response) => {
        const labels = [];
        const data = [];

        response.data.map((element) => {
          labels.push(element.day);
        });

        response.data.map((element) => {
          data.push(element.dia);
        });

        setInfosLabelGraphsSimulations(labels);
        setInfosDataGraphsSimulations(data);
      })
      .catch(() => {
        // NotificationManager.error(
        //   'Tivemos dificuldades para buscar as informações sobre os gráficos das simulações! Tente novamente!',
        //   'Atenção'
        // );
      });
  }

  return (
    <RenderPage title="Dashboard">
      <Container maxWidth="xl">
        {showAlert && (
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={12}>
              <Fade in={showAlert} style={{ transformOrigin: '0 0 0' }}>
                <Alert severity={warning.severity}>
                  <Typography variant="subtitle2">Aviso: {warning.warning}</Typography>
                </Alert>
              </Fade>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Usuários cadastrados"
              total={infosSummaryClients}
              icon={'eva:person-add-outline'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Parceiros cadastrados"
              total={infosSummarySimulations}
              icon={'ant-design:exception-outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Vendas finalizadas"
              total={infosSummaryClientsApproved}
              icon={'eva:person-done-outline'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Simulações finalizadas"
              total={infosSummarySimulationsFinished}
              icon={'ant-design:file-done-outlined'}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppGraphs
              title="Cadastros de usuários realizadas por período"
              subheader="Acompanhe o desempenho dos cadastros de usuários no último ano separadas por período"
              chartLabels={infosLabelsGraphsClients}
              name="Vendas"
              chartData={[
                {
                  name: 'Vendas',
                  type: 'line',
                  fill: 'solid',
                  data: infosDataGraphsClients
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppGraphs
              title="Cadastros de parceiros realizadas por período"
              subheader="Acompanhe o desempenho dos cadastros de parceiros realizados no último ano separadas por período"
              chartLabels={infosLabelsGraphsSimulations}
              name="Simulações"
              chartData={[
                {
                  name: 'Simulações',
                  type: 'line',
                  fill: 'solid',
                  color: '#FAE400',
                  data: infosDataGraphsSimulations
                }
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </RenderPage>
  );
};

export default DashboardApp;
